import React, {Fragment} from 'react';
import {GatsbyImage} from  "gatsby-plugin-image";
import {MdFileDownload, MdPhotoCamera} from "react-icons/md";
import { useWindowDimensions } from '../context/WindowDimensionsProvider';

const ImageInfo = (props) => {
  const { windowHeight, footerHeight } = useWindowDimensions()
  return (
    <>
    <div className="image flex center basePad" style={{minHeight: windowHeight-footerHeight}}>
      <div className="imgWrap">
        <GatsbyImage image={props.post.img.childImageSharp.gatsbyImageData} alt={`Photo by: ${props.post.photo}`}/>
        <div><i>{props.post.alt}</i></div>
        <div className="flex row">
          <MdPhotoCamera className="iconz" size={20}/>
          <h4>:&nbsp;{props.post.photo}&nbsp;</h4>
          {/*<a
            id="downloadImageButton"
            href={props.post.img.childImageSharp.gatsbyImageData.src}
            download={`Stein Erik Olsen - Photo by ${props.post.photo}.jpg`}
            >
            <MdFileDownload size={30} style={{marginTop: '-0.5rem'}} />
          </a>*/}
        </div>
      </div>
    </div>
    </>
  )
}
export default ImageInfo;
